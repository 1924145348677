<script setup lang="ts">
import TheHeader from "~/nuxt-ui/components/layout/TheHeader.vue";
import TheFooter from "~/nuxt-ui/components/layout/TheFooter.vue";

const toolbarVisible = ref(true);
const eventListeners = ["scroll", "resize"];
onMounted(() => {
  eventListeners.forEach(event => {
    window.addEventListener(event, () => {
      toolbarVisible.value = !(window.scrollY > 28 || window.innerWidth < 1200);
    });
  });
});

onErrorCaptured((err, target, info) => {
  if (err.statusCode !== 404) {
    console.error("Layout error: ", err, info);
    return false;
  }
});
</script>

<template>
  <the-header :toolbar-visible="toolbarVisible"></the-header>
  <main class="main" :class="toolbarVisible ? 'mt-0' : 'mt-[130px] lg:mt-[152px]'">
    <slot />
  </main>
  <the-footer></the-footer>
</template>

<style scoped>
.main {
  min-height: calc(theme(height.screen) - 20vh);
}
</style>
